<template>
	<div class="bodys" v-loading="loading">
		<div class="left">
			<el-tabs tab-position="left" v-model="typefirst" @tab-click="tabpaneclick" editable @edit="handleTabsEdit1">
				<el-tab-pane :name="item.id+''" v-for="(item,index) in typeonelist" :key="index">
					<span slot="label"><i class="el-icon-edit" @click.stop="addtabs(1,item)"></i>
						{{item.shixunName}}</span>
					<el-tabs v-model="typesecond" @tab-click="tabpaneclick" editable @edit="handleTabsEdit2">
						<el-tab-pane :name="item.id+'-'+exim.id" v-for="(exim,types) in typesecondlist" :key="types">
							<span slot="label"><i class="el-icon-edit edits" @click.stop="addtabs(2,exim)"></i>
								<span class="tests">{{exim.nextName}}</span></span>
							<el-button class="buttones" type="primary" icon="el-icon-plus"
								@click="$router.push({ path: 'addDemandScheme',query:{type:typesecond}})">新增方案
							</el-button>
							<el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark"
								style="width: 100%">
								<!-- <el-table-column type="selection" width="55">
								</el-table-column> -->
								<el-table-column type="index" label="序号" width="50">
								</el-table-column>
								<el-table-column label="名称" prop="equName">
								</el-table-column>
								<el-table-column label="简介" prop="equContent">
								</el-table-column>
								<el-table-column label="创建时间">
									<template slot-scope="scope" v-if="scope.row.equCreateTime">
										{{moment(scope.row.equCreateTime).format('YYYY-MM-DD HH:mm:SS')}}
									</template>
								</el-table-column>
								<el-table-column label="价格" prop="equPrice" show-overflow-tooltip width="160px">
									<template slot-scope="scope" v-if="scope.row.equPrice">
										{{scope.row.equPrice}}
										{{scope.row.unit == 0?'元':'万元'}}
									</template>
								</el-table-column>
								<el-table-column label="价格区间(预算方案)" show-overflow-tooltip>
									<template slot-scope="scope">
										{{scope.row.equBudget}} <i size="mini" class="el-icon-edit editsa"
											@click="equBudgetedit(scope.row)"></i>
									</template>
								</el-table-column>
								<el-table-column label="操作" width="130">
									<template slot-scope="scope">
										<el-button size="mini"
											@click="$router.push({ path: 'addDemandScheme',query:{id:scope.row.id,type:typesecond}})"
											type="primary" icon="el-icon-edit">
										</el-button>
										<el-button size="mini" type="primary" icon="el-icon-delete"
											@click="deleteProgramme(scope.row.id)"></el-button>
									</template>
								</el-table-column>
							</el-table>
						</el-tab-pane>
					</el-tabs>
					<div class="pagination" v-if="totalnumber>10">
						<el-pagination :current-page="pagenum" @current-change="paginationchange" background
							layout="prev, pager, next" :total="totalnumber">
						</el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
			<el-input v-model="classificationvalue" placeholder="请输入内容"></el-input>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="add()">确 定</el-button>
				<el-button @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
		<el-dialog :visible.sync="equBudgetVisible" :close-on-click-modal="false">
			<el-select v-model="budget" @change="budgetselectchange">
				<el-option :value="1" :label="'0-300万元'"></el-option>
				<el-option :value="2" :label="'300-1000万元'"></el-option>
				<el-option :value="3" :label="'1000万元以上'"></el-option>
			</el-select>
			<span slot="footer" class="dialog-footer" v-if="buttonshows">
				<el-button type="primary" @click="equBudgetadd()">确 定</el-button>
				<el-button @click="equBudgetVisible = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import Moment from 'moment'
	export default {
		components: {},
		data() {
			return {
				totalnumber: 0,
				pagenum: 1,
				userId: sessionStorage.getItem('userId'),
				roleId: sessionStorage.getItem('roleId'),
				loading: false,
				dialogVisible: false,
				equBudgetVisible: false,
				moment: Moment,
				typefirst: '3',
				typesecond: '',
				editshow: false,
				dialogindex: 0,
				selectionshow: false,
				buttonshows: false,
				tableData: [],
				typeonelist: [],
				typesecondlist: [],
				classificationvalue: '',
				classificationtype: 1,
				FirstId: 0,
				SecondId: 0,
				planBudgetdata: '',
				budget: ''
			}
		},
		created() {
			if (this.$route.query.ftypes) {
				let dast = this.$route.query.ftypes
				this.typesecond = dast
				this.typefirst = this.$commonFun.getBeforStr(dast, '-')
				// console.log(this.typefirst)
				// console.log(this.typesecond)
			}
			this.getqueryTrainType()

		},
		mounted() {

		},
		methods: {
			paginationchange(val) {
				this.pagenum = val
				this.querySortPlan(this.typefirst, this.$commonFun.getAfterStr(this.typesecond, '-'))
			},
			budgetselectchange(val) {
				this.buttonshows = false
				let gh = 0
				if (this.planBudgetdata.unit == 0) {
					gh = this.planBudgetdata.equPrice / 10000
				} else {
					gh = this.planBudgetdata.equPrice
				}
				switch (val) {
					case 1:
						gh > 300 ? this.$message({
							type: 'warning',
							message: '价格超过区间最大值300万元'
						}) : '';
						break;
					case 2:
						gh > 300 ? gh > 1000 ? this.$message({
							type: 'warning',
							message: '价格超过区间最大值1000万元'
						}) : '' : this.$message({
							type: 'warning',
							message: '价格小于区间最小值300万元'
						})
						break;
					case 3:
						gh > 1000 ? '' : this.$message({
							type: 'warning',
							message: '价格小于区间最小值1000万元'
						})
						break;
				}
				this.buttonshows = true
			},
			equBudgetadd() {
				this.$post('updatePlanBudget', {
					planId: this.planBudgetdata.id,
					userId: this.userId,
					budget: this.budget
				}).then((res) => {
					// console.log(res)
					this.equBudgetVisible = false
					this.querySortPlan(this.typefirst, this.$commonFun.getAfterStr(this.typesecond,
						'-'))
				})
			},
			equBudgetedit(data) {
				// console.log(data)
				this.planBudgetdata = data
				this.equBudgetVisible = true
			},
			deleteProgramme(id) {
				this.$confirm('此操作将删除该方案, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post('deleteSortPlanById', {
						id: id
					}).then(() => {
						this.$message({
							type: 'success',
							message: "删除成功"
						});
						this.querySortPlan(this.typefirst, this.$commonFun.getAfterStr(this.typesecond,
							'-'))
					})
				})
			},
			//修改
			addtabs(type, data) {
				this.dialogVisible = true
				if (type == 1) {
					this.classificationtype = 1
					this.FirstId = data.id
					this.classificationvalue = data.shixunName
				} else if (type == 2) {
					// console.log(data)
					this.classificationtype = 2
					this.SecondId = data.id
					this.FirstId = data.superId
					this.classificationvalue = data.nextName
				}
			},
			//一级二级分类添加修改
			add(Id) {
				if (this.classificationvalue) {
					if (this.classificationtype == 1) {
						this.$post('addTrainType', {
							id: this.FirstId,
							name: this.classificationvalue
						}).then(() => {
							this.$message({
								type: 'success',
								message: this.FirstId != 0 ? '修改成功' : '新增成功'
							});
							this.getqueryTrainType()
							this.dialogVisible = false
						})
					} else if (this.classificationtype == 2) {
						this.$post('addSecondSort', {
							id: this.SecondId,
							secondId: this.FirstId,
							secondName: this.classificationvalue
						}).then(() => {
							this.$message({
								type: 'success',
								message: this.SecondId != 0 ? '修改成功' : '新增成功'
							});
							this.getSecondSort(this.FirstId, 1)
							this.dialogVisible = false
						})
					}
				} else {
					this.$message({
						type: 'warning',
						message: '请输入内容'
					});
				}
			},
			//点击tab栏一级分类删除添加时
			handleTabsEdit1(targetName, action) {
				this.classificationtype = 1
				if (action == 'add') {
					this.FirstId = 0
					this.dialogVisible = true
					this.classificationvalue = ''
				} else if (action == 'remove') {
					this.$confirm('此操作将删除数据, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$post('deleteTrainType', {
							id: targetName,
						}).then(() => {
							this.$message({
								type: 'success',
								message: '删除成功'
							});
							this.getqueryTrainType()
						})
					}).catch(() => {});
				}
			},
			//点击tab栏二级分类删除添加时
			handleTabsEdit2(targetName, action) {
				this.classificationtype = 2
				if (action == 'add') {
					this.FirstId = this.typefirst
					this.SecondId = 0
					this.dialogVisible = true
					this.classificationvalue = ''
				} else if (action == 'remove') {
					this.$confirm('此操作将删除数据, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$post('deleteSecondSortById', {
							id: this.$commonFun.getAfterStr(targetName, '-'),
						}).then(() => {
							this.$message({
								type: 'success',
								message: '删除成功'
							});
							this.getSecondSort(this.$commonFun.getBeforStr(targetName, '-'))
						})
					}).catch(() => {});
				}
			},
			//装修风格点击tab栏
			tabpaneclick(val) {
				this.$router.push({
					query: {}
				})
				// console.log(this.typefirst)
				// console.log(this.typesecond)
				if (val.name.indexOf('-') == -1) {
					this.classificationtype = 1
					this.getSecondSort(val.name, 1)
				} else {
					this.classificationtype = 2
					let w = this.$commonFun.getBeforStr(this.typesecond, '-')
					let r = this.$commonFun.getAfterStr(this.typesecond, '-')
					let styleId = ''
					if (this.typefirst == w) {
						styleId = r
					}
					this.querySortPlan(this.typefirst, styleId)
				}
			},
			async getSecondSort(id, type) {
				// console.log(id)
				// console.log(type)
				// console.log(this.typefirst)
				// console.log(this.typesecond)
				await this.$post('getSecondSort', {
					superId: id
				}).then((res) => {
					this.typesecondlist = res
					if (res && res.length > 0) {
						if (type) {
							this.typesecond = id + '-' + res[0].id
						}
						let g = this.typesecond.indexOf('-') == -1 ? this.typesecond : this.$commonFun
							.getAfterStr(this.typesecond, '-')
						this.querySortPlan(this.typefirst, g)
					}

				})
			},
			async querySortPlan(id1, id2) {
				// console.log(id1)
				// console.log(id2)
				this.loading = true
				this.totalnumber = 0
				this.budget = ''
				this.tableData = []
				await this.$post('querySortPlan', {
					equId: id1,
					equNextId: id2,
					pagenum: this.pagenum,
					pagesize: 10
				}).then((res) => {
					this.loading = false
					let data = res.list
					this.totalnumber = res.total
					if (data && data.length > 0) {
						data.forEach((e, m) => {
							if (e.equBudget == 0) {
								e.equBudget = '无'
							} else if (e.equBudget == 1) {
								e.equBudget = '0-300万元'
							} else if (e.equBudget == 2) {
								e.equBudget = '300-1000万元'
							} else if (e.equBudget == 3) {
								e.equBudget = '1000万元以上'
							}
						})
					}
					this.tableData = data
				})
				this.$set(this.tableData)
			},
			async getqueryTrainType() {
				await this.$get('queryTrainType').then((res) => {
					if(res&&res.length>0){
						this.typeonelist = res
						if (!this.$route.query.ftypes) {
							this.typefirst = res[0].id + ''
							this.getSecondSort(this.typefirst, 1)
						} else {
							this.getSecondSort(this.typefirst)
						}
					}
					else{
						this.typeonelist = []
					}
				})
			},
			handleSelectionChange(val) {
				// console.log(val)
				if (val.length > 0) {
					this.selectionshow = true
				} else {
					this.selectionshow = false
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.bodys {
		>* {
			display: inline-block;
			vertical-align: top;
		}

		.left {
			width: 100%;
		}

		.buttones {
			margin: 0 0 40px 0;
		}

		.editsa {
			cursor: pointer;
			margin-left: 20px;
		}

		.pagination {
			text-align: center;
			margin: 40px 0;
		}
	}

	.tests {
		margin: 0 20px;
	}
</style>
<style>
	.el-tabs__nav{
		overflow-x: auto;
		    width: 100%;
	}
	.el-tabs__new-tab {
		width: 30px;
		height: 30px;
		line-height: 30px;
		font-size: 20px;
	}

	.el-tabs--left .el-tabs__header.is-left .el-tabs__new-tab {
		float: left;
	}

	.left .edits {
		color: #000FFF;
	}
</style>
